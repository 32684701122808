import { API } from "../network/API";
import countys from "./countys.json";

export enum BookingActivity {
  CANCELED_ACTIVITY = "CANCELED_ACTIVITY",
  NO_ACTIVITY = "NO_ACTIVITY",
  LOW_ACTIVITY = "LOW_ACTIVITY",
  HIGH_ACTIVITY = "HIGH_ACTIVITY",
  NEW_ACTIVITY = "NEW_ACTIVITY",
}

export interface Appointment {
  bookingActivity: BookingActivity;
  countyId: number;
  created: string;
  stationId: number;
  /**
   * ISO datetime
   */
  time: string;
}

export function getAppointments() {
  return API.get<Appointment[]>("/times");
}

export function getBookingLink(stationId: number, countyId: number) {
  const county = countys.find((county) => county.countyId === countyId);

  if (!county) {
    throw new Error(`County with id ${countyId} not found`);
  }

  const encodedUrl = encodeURIComponent(
    `https://bokapass.nemoq.se/Booking/Booking/Index/${county.uriName}`
  );

  return `${API.getBaseUrl()}/link?stationId=${stationId}&linkType=BOOK&url=${encodedUrl}`;
}
