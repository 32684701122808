import React, { useEffect, useState } from "react";
import { CallToActionLink } from "../../components/CallToAction/CallToActionLink";
import { Card } from "../../components/card/Card";
import { InnerCard } from "../../components/card/InnerCard";
import { CountyName } from "../../components/CountyName";
import { LabeledText } from "../../components/LabeledText/LabeledText";
import { StationName } from "../../components/StationName";
import rawCountys from "../../data/countys.json";
import { DateTime } from "luxon";

import "./Home.scss";
import {
  Appointment,
  BookingActivity,
  getAppointments,
  getBookingLink,
} from "../../data/Appointments";

export const HOME_PATH = "/";

const countys = [...rawCountys].sort((a, b) => a.name.localeCompare(b.name));

export const Home = () => {
  const [appointmentData, setAppointmentData] = useState<Appointment[]>([]);

  useEffect(() => {
    getAppointments().then(setAppointmentData);
  }, []);

  const earliestAppointment = appointmentData.reduce<Appointment | undefined>(
    (acc, curr) => {
      if (!acc || DateTime.fromISO(curr.time) < DateTime.fromISO(acc.time)) {
        return curr;
      }
      return acc;
    },
    undefined
  );

  return (
    <div className="page-home">
      <section className="hero">
        <div className="hero-content-wrapper">
          <div className="hero-content">
            <h1>Hitta passtider</h1>
            <p>
              Behöver du ett nytt pass? Kan du inte vänta i 6 månader? Inte vi
              heller. Så vi byggde en sida som listar lediga tider för alla
              passexpeditioner så att du ska slippa spendera fredagkvällen med
              att hitta den tidigaste tiden nära dig.
            </p>
          </div>
          {earliestAppointment && (
            <div className="hero-content">
              <Card>
                <h2>Första lediga tid</h2>
                <p>
                  Den tidigaste lediga tiden i Sverige just nu är{" "}
                  <strong>
                    {DateTime.fromISO(earliestAppointment.time).toFormat(
                      /* "yyyy-MM-dd HH:mm" */
                      "DDDD",
                      { locale: "sv" }
                    )}
                  </strong>{" "}
                  i{" "}
                  <strong>
                    <StationName stationId={earliestAppointment.stationId} />,{" "}
                    <CountyName countyId={earliestAppointment.countyId} />.
                  </strong>
                </p>
                <CallToActionLink
                  to={getBookingLink(
                    earliestAppointment.stationId,
                    earliestAppointment.countyId
                  )}
                  text="Boka nu"
                />
              </Card>
            </div>
          )}
        </div>
      </section>

      <section className="card-list">
        {countys.map((county) => {
          const countyOffices = appointmentData.filter(
            (office) => office.countyId === county.countyId
          );
          return (
            <Card>
              <h2>
                <CountyName countyId={county.countyId} />
              </h2>
              {countyOffices.map((office) => {
                return (
                  <InnerCard>
                    <h3>
                      <StationName stationId={office.stationId} />
                    </h3>
                    <div className="office-row">
                      <div className="flex">
                        <LabeledText label="Första tillgängliga tid">
                          <span className="capitalize">
                            {DateTime.fromISO(office.time).toFormat(
                              /* "yyyy-MM-dd HH:mm" */
                              "DDDD",
                              { locale: "sv" }
                            )}
                          </span>
                        </LabeledText>
                        {office.bookingActivity ===
                          BookingActivity.CANCELED_ACTIVITY && (
                          <LabeledText label="Avbokning">
                            Hög risk att tiden försvinner snart
                          </LabeledText>
                        )}
                      </div>
                      <div>
                        <CallToActionLink
                          to={getBookingLink(office.stationId, office.countyId)}
                          text="Boka tid"
                        />
                      </div>
                    </div>
                  </InnerCard>
                );
              })}
            </Card>
          );
        })}
      </section>
    </div>
  );
};
