import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components/layout/Layout";
import { About, ABOUT_PATH } from "./pages/about/About";
import { Home, HOME_PATH } from "./pages/home/Home";
import { Provisional, PROVISIONAL_PATH } from "./pages/provisional/Provisional";

export const Router = () => {
  return (
    <main>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path={HOME_PATH} element={<Home />} />
            <Route path={PROVISIONAL_PATH} element={<Provisional />} />
            <Route path={ABOUT_PATH} element={<About />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </main>
  );
};
