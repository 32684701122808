import React, { VoidFunctionComponent } from "react";
import rawStations from "../data/stations.json";

interface Props {
  stationId: number;
}

const stations = rawStations.flatMap((county) => county.stations);

export const StationName: VoidFunctionComponent<Props> = ({ stationId }) => {
  const station = stations.find((station) => station.stationId === stationId);
  return (
    <React.Fragment>
      {station?.stationName ?? "Okänd passexpedition"}
    </React.Fragment>
  );
};
