import React, { VoidFunctionComponent } from "react";
import "./CallToAction.scss";

interface Props {
  to: string;
  text: string;
}

export const CallToActionLink: VoidFunctionComponent<Props> = ({
  text,
  to,
}) => {
  return (
    <a
      href={to}
      target="_blank"
      rel="noreferrer noopeners"
      className="cta cta-link"
    >
      {text}
    </a>
  );
};
